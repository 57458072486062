import React from 'react';

import { Spread } from 'editor/src/store/design/types';

import useSpreadTemplatePreview from 'editor/src/util/design/useSpreadTemplatePreview';

import IconBin from 'editor/src/component/Icon/IconBin';
import { RequestRenderFn } from 'editor/src/component/SpreadPreview';
import { SpreadPreviewOptions } from 'editor/src/component/SpreadPreview/createSpreadPreview';

import styles from './index.module.scss';

interface Props {
  id: string;
  name: string;
  spread: Spread;
  requestRender: RequestRenderFn;
  onDelete: (id: string, name: string) => void;
  onApply: (id: string) => void;
}

const PREVIEW_DIMENSION = 89;

function TemplateItem({ id, name, spread, requestRender, onDelete, onApply }: Props) {
  const previewOptions: SpreadPreviewOptions = {
    showBlanks: true,
    showEmptyImages: true,
  };

  const { preview } = useSpreadTemplatePreview(
    requestRender,
    spread,
    id,
    0,
    { dimension: 'both', value: PREVIEW_DIMENSION },
    previewOptions,
    true,
  );

  const onDeleteClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onDelete(id, name);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.preview}
        onClick={() => onApply(id)}
        style={{
          backgroundImage: preview ? `url(${preview.dataURL})` : undefined,
        }}
      />
      <div className={styles.title}>{name}</div>
      <div className={styles.removeButton} onClick={onDeleteClicked}>
        <IconBin className={styles.removeIcon} />
      </div>
    </div>
  );
}

export default React.memo(TemplateItem);
